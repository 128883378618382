<template>
  <div class="schoolList">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'schoolList'">
        <div class="search_sList">
          <el-tabs v-model="activeName" @tab-click="statusEvent">
            <!-- <el-tab-pane
              :label="`全部学校（${tabData.totalNum}）`"
              name="first"
            ></el-tab-pane>
            <el-tab-pane
              :label="`试用中（${tabData.trialCount}）`"
              name="second"
            ></el-tab-pane>
            <el-tab-pane
              :label="`合约中（${tabData.runCount}）`"
              name="third"
            ></el-tab-pane>
            <el-tab-pane
              :label="`已过期（${tabData.expireCount}）`"
              name="fourth"
            ></el-tab-pane> -->
            <el-tab-pane :label="`商用`" name="third"></el-tab-pane>
            <el-tab-pane :label="`演示`" name="second"></el-tab-pane>
            <el-tab-pane :label="`全部学校`" name="first"></el-tab-pane>
            <el-tab-pane :label="`已下架学校`" name="fourth"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="100px"
            >
              <el-form-item label="所在省份">
                <el-select
                  v-model="formInline.region"
                  placeholder="所在省份"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in provinceData"
                    :key="item.provinceId"
                    :label="item.provinceName"
                    :value="item.provinceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="平台名称">
                <el-input
                  v-model="formInline.nameSearch"
                  placeholder="平台名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="合同状态">
                <el-select
                  v-model="formInline.cooperationStatus"
                  placeholder="合同状态"
                  clearable
                >
                  <el-option label="全选" value=""></el-option>
                  <el-option label="试用中" value="TRIAL"></el-option>
                  <el-option label="合约中" value="RUN"></el-option>
                  <el-option label="已过期" value="EXPIRE"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校编码">
                <el-input
                  v-model="formInline.encoding"
                  placeholder="学校编码"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="有无设置客服">
                <el-select
                  v-model="formInline.customerFlag"
                  placeholder="有无设置客服"
                  clearable
                >
                  <el-option :label="item" :value="key" v-for="(item, key) in $common.cusSetData" :key="key"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="时间范围">
                <el-date-picker
                  clearable
                  @change="timeEvent"
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item> -->
              <!-- <el-form-item>
                <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
                <el-button type="info" @click="resetEvent" plain>重置</el-button>
              </el-form-item> -->
            </el-form>
            <div class="btn_area">
              <el-button
                class="mainBtn"
                @click="onSubmit"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetEvent" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button
              v-allow="'sys_school_add'"
              class="mainBtn"
              @click="addSEvent"
              plain
              >+添加新学校</el-button
            >
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
          >
            <el-table-column label="序号" align="center" width="50">
              <template slot-scope="scope">
                {{
                  (formInline.page - 1) * formInline.pageSize + scope.$index + 1
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="平台名称"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="province"
              label="所在省份"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="encoding"
              label="学校编码"
              align="center"
              width="90"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.encoding"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.encoding }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="学校状态"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span
                  @click="jumpStatus(scope.row)"
                  :class="[scope.row.status == 5 ? 'st_three' : scope.row.status != 3 ?'st_one' : 'st_two']"
                  >{{
                    scope.row.status == 1
                      ? "待审核"
                      : scope.row.status == 2
                      ? "待发布"
                      : scope.row.status == 3
                      ? "已发布"
                      :scope.row.status == 4
                      ? "审核不通过" 
                      : "已下架"
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="cooperationStatus"
              label="合同状态"
              align="center"
              width="80"
              :formatter="regDateEvent"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="expirationTime"
              label="合同到期时间"
              align="center"
              width="180">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.expirationTime, 'datetime')}}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="payProportion"
              label="缴费比例"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="studentCount"
              label="在校人数"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span
                  @click="stuCount(scope.row)"
                  style="color: #1282ff; cursor: pointer"
                  >{{ scope.row.studentCount }}</span
                >
                <!-- <span @click="stuCount(scope.row)" style="color:#1282FF;cursor:pointer;">2000</span> -->
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="教学计划"
              align="center"
              width="100"
              v-if="$isShowModle('sys_teach_plan_query')"
            >
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.status != 3"
                  size="small"
                  type="text"
                  @click="jumpTeach(scope.row)"
                  >教学计划</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="学生管理"
              align="center"
              width="90"
              v-if="$isShowModle('sys_student_query')"
            >
              <template slot-scope="scope">
                <el-button
                  size="small"
                  :disabled="scope.row.status != 3"
                  type="text"
                  @click="jumpStudent(scope.row)"
                  >学生管理</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="课程管理"
              align="center"
              width="90"
            >
              <template slot-scope="scope">
                <el-button
                  size="small"
                  :disabled="scope.row.status != 3"
                  type="text"
                  @click="jumpCourse(scope.row)"
                  >课程管理</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="webSite"
              label="平台访问地址"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.webSite"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div @click="jumpWebSite(scope.row)" class="ellipsis point">{{ scope.row.webSite }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="学校发布"
              align="center"
              width="110"
            >
              <template slot-scope="scope">
                <el-button
                  @click="release(scope.row)"
                  class="mainBtn"
                  v-if="scope.row.status == 2 && $isShowModle('sys_school_publish')"
                  >发布</el-button
                >
                <el-button
                  type="info"
                  plain
                  disabled
                  v-if="scope.row.status != 2 && scope.row.status != 3"
                  >发布</el-button
                >
                <el-button
                  type="info"
                  plain
                  disabled
                  v-if="scope.row.status == 3"
                  >已发布</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="publishTime"
              label="学校上线时间"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                {{$way.timestampReturnDate(scope.row.publishTime)}}
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="学校下架"
              align="center"
              width="120"
              v-if="$isShowModle('sys_school_off')"
            >
              <template slot-scope="scope">
                <el-button
                  @click="offSchool(scope.row)"
                  class="st_one"
                  v-if="scope.row.status == 3"
                  >下架</el-button
                >
                <el-button
                @click="onSchool(scope.row)"
                  class="mainBtn"
                  v-if="scope.row.status == 5"
                  >重新上架</el-button
                >
                <el-button
                  disabled
                  v-if="scope.row.status != 5 && scope.row.status != 3"
                  >下架</el-button
                >
            </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="160">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="text"
                  @click="editSchool(scope.row)"
                  v-allow="'sys_school_edit'"
                  >编辑</el-button
                >
                <el-button
                  size="small"
                  type="text"
                  v-show="scope.row.status == 3"
                  @click="handleClick(scope.row)"
                  >模拟登录</el-button
                >
                <el-button
                  size="small"
                  type="text"
                  v-show="scope.row.status == 3"
                  @click="jumpResources(scope.row)"
                  >资源匹配模板</el-button
                >
                <el-button
                  size="small"
                  type="text"
                  v-show="scope.row.status == 3 && $isShowModle('sys_school_set')"
                  @click="setEvent(scope.row)"
                  >设置</el-button
                >
                <!-- <el-button
                  size="small"
                  type="text"
                  v-show="scope.row.status == 3"
                  @click="dialogDiyEvent(scope.row, 'open')"
                  >得分规则</el-button
                > -->
                <!-- <el-dropdown>
                  <span class="el-dropdown-link">
                     <el-button type="text">操作选择</el-button><i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="pubDropMenu">
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="editSchool(scope.row)" v-allow="'sys_school_edit'">编辑</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="handleClick(scope.row)">模拟登录</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="jumpTeach(scope.row)">教学计划</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="jumpStudent(scope.row)">学生管理</el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>

    <dialogDiy
      :isShow="isShowRule"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div class="ruleScoring_area" slot="dialogContent">
        <el-scrollbar class="dialogScroll">
          <div class="block_module">
            <div class="m-title fans">成绩权重分配：总比例=100%</div>
            <div class="m-cont achievement">
              <div class="a_pub">
                <div class="achieve">平时成绩</div>
                <el-input
                  v-model.number="editData.usualPerformance"
                  maxlength="3"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="checkNum('performance', 'usualPerformance', $event)"
                ></el-input>
                <div>%</div>
              </div>
              <div class="a_pub">
                <div class="achieve">期末成绩</div>
                <el-input
                  v-model.number="editData.finalPerformance"
                  maxlength="3"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="checkNum('performance', 'finalPerformance', $event)"
                ></el-input>
                <div>%</div>
              </div>
              <div class="a_pub">
                <div class="achieve">其他成绩</div>
                <el-input
                  v-model.number="editData.otherPerformance"
                  maxlength="3"
                  onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="checkNum('performance', 'otherPerformance', $event)"
                ></el-input>
                <div>%</div>
              </div>
            </div>
          </div>
          <div class="block_module">
            <div class="m-title fans">平时成绩分配：总分=100分</div>
            <div class="m-cont">
              <el-form
                :inline="true"
                :model="editData"
                class="demo-rule-form"
                label-width="100px"
              >
                <el-form-item label="学习登录:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      v-model.number="editData.loginItem.maxScore"
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @blur="checkNum('maxScore', 'loginItem', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">每次</div>
                    <el-input
                      v-model.number="editData.loginItem.value"
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @blur="checkNum('value', 'loginItem', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                </el-form-item>
                <el-form-item label="课件学习:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.courseStudy.maxScore"
                      @blur="checkNum('maxScore', 'courseStudy', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">连续学习</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.courseStudy.minute"
                      @blur="checkNum('minute', 'courseStudy', $event)"
                    ></el-input>
                    <div>分钟</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">得</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.courseStudy.value"
                      @blur="checkNum('value', 'courseStudy', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                </el-form-item>
                <el-form-item label="直播学习:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.liveStudy.maxScore"
                      @blur="checkNum('maxScore', 'liveStudy', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">连续学习</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.liveStudy.minute"
                      @blur="checkNum('minute', 'liveStudy', $event)"
                    ></el-input>
                    <div>分钟</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">得</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.liveStudy.value"
                      @blur="checkNum('value', 'liveStudy', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                </el-form-item>
                <el-form-item label="教辅材料学习:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.ebookStudy.maxScore"
                      @blur="checkNum('maxScore', 'ebookStudy', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">连续学习</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.ebookStudy.minute"
                      @blur="checkNum('minute', 'ebookStudy', $event)"
                    ></el-input>
                    <div>分钟</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">得</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.ebookStudy.value"
                      @blur="checkNum('value', 'ebookStudy', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                </el-form-item>
                <el-form-item label="提问答疑:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.interactive.maxScore"
                      @blur="checkNum('maxScore', 'interactive', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">发起评论</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.interactive.value"
                      @blur="checkNum('value', 'interactive', $event)"
                    ></el-input>
                    <div>分/次</div>
                  </div>
                </el-form-item>
                <el-form-item label="课程作业:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.work.maxScore"
                      @blur="checkNum('maxScore', 'work', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                  <div class="a_pub_new">
                    <div class="achieve">每次</div>
                    <el-input
                      maxlength="3"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model.number="editData.work.value"
                      @blur="checkNum('value', 'work', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                </el-form-item>
                <el-form-item label="学习中心评分:">
                  <div class="a_pub_new">
                    <div class="achieve">最高</div>
                    <el-input
                      maxlength="3"
                      v-model.number="editData.stations.maxScore"
                      onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @blur="checkNum('maxScore', 'stations', $event)"
                    ></el-input>
                    <div>分</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- <div class="block_module">
          <div class="m-cont">
            <el-radio-group v-model="radio">
              <el-radio :label="0">应用于当前课程</el-radio>
              <el-radio :label="1">应用于全部课程</el-radio>
              <el-radio :label="2">应用于直播课程</el-radio>
              <el-radio :label="3">应用于录播课程</el-radio>
              <el-radio :label="4">应用于面授课程</el-radio>
            </el-radio-group>
          </div>
        </div> -->
          <div class="block_module final">
            <div style="color: red">注：</div>
            <div class="tips">
              <p>1、已结束课程的得分规则不会被修改；</p>
              <p>
                2、课件与教辅材料学习连续学习的得分时间一般设置为2-5分钟，以避免学生学习完成后不得分；
              </p>
              <p>3、不同授课方式请设置不同得分规则，避免学生学习完成后不得分</p>
            </div>
          </div>
          <div class="rule_btn_area">
            <el-button type="primary" @click="enterEvent">确 定</el-button>
            <el-button @click="dialogDiyEvent">取 消</el-button>
          </div>
        </el-scrollbar>
      </div>
    </dialogDiy>
    
  </div>
</template>

<script>
import publicChildren from "components/publicChildren";
import Pagination from "../Pagination";
import way from "../../api/encapsulation";
import dialogDiy from "../commonModule/dialogDiy.vue";
export default {
  name: "schoolList",
  components: {
    publicChildren,
    Pagination,
    dialogDiy
  },
  data() {
    return {
      activeName: "third",
      // timeVal: '',
      formInline: {
        customerFlag: null,
        encoding: null,
        cooperationStatus: "",
        // startTime: '',
        // endTime: '',
        nameSearch: "",
        region: "",
        page: 1,
        pageSize: 10,
        nature: 1
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      provinceData: [], // 省份数据
      tabData: {
        expireCount: 0, // 已过期
        runCount: 0, // 合约中
        trialCount: 0, // 试用中
        totalNum: 0 // 全部
      },
      asideMenuData: [],

      // 得分规则
      tenantId: "",
      isShowRule: false,
      dialogDiyData: {
        title: "修改得分规则",
        sizeStyle: { width: "800px", height: "600px" }
      },
      editData: {
        courseName: "",
        isall: 0,
        courseStudy: {
          maxScore: "",
          minute: "",
          value: ""
        },

        ebookStudy: {
          maxScore: "",
          minute: "",
          value: ""
        },
        finalPerformance: "",
        id: "",
        interactive: {
          maxScore: "",
          minute: "",
          value: ""
        },
        isUalPerformance: "",
        liveStudy: {
          maxScore: "",
          minute: "",
          value: ""
        },
        loginItem: {
          maxScore: "",
          minute: "",
          value: ""
        },
        otherPerformance: "",
        scoreRule: "",
        stations: {
          maxScore: "",
          minute: "",
          value: ""
        },
        teachingMethod: "",
        teachingPlanName: "",
        usualPerformance: "",
        work: {
          maxScore: "",
          minute: "",
          value: ""
        }
      }
    };
  },
  created() {
    this.getProviceEvent();
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "schoolList") {
          this.getTableData();
          this.asideMenuData = JSON.parse(
            sessionStorage.getItem("asideMenuData")
          );
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    handleKeydown(event) {
      if (!this.isShowRule) {
        this.onSubmit()
      }
    },
    timeEvent(val) {
      if (!val) {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    statusEvent(tab) {
      // console.log(tab);
      let val = "";
      let nature = null;
      switch (tab.paneName) {
        case "first":
          val = "";
          nature = null;
          break;
        case "second":
          val = "TRIAL";
          nature = 2;
          break;
        case "third":
          val = "RUN";
          nature = 1;
          break;
        case "fourth":
          nature = 3;
          // val = "EXPIRE";
          break;
      }
      // this.formInline.cooperationStatus = val;
      this.formInline.nature = nature;
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTableData();
    },
    // 获取表格数据
    getTableData() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getListData(obj, res => {
        this.loading = false;
        if (res.code == 0) {
          // console.log(res)
          let { runCount, trialCount, expireCount } = res.data;
          let { records, current, size, total } = res.data.page;
          let totalNum = expireCount + runCount + trialCount;
          this.tableData = records;
          this.tabData = {
            expireCount,
            runCount,
            trialCount,
            totalNum
          };
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getProvice({}, res => {
        if (res.code == 0) {
          this.provinceData = res.data;
        }
      });
    },
    // 数据检测是否合格
    checkNum(type, row, $event) {
      if (type == "performance") {
        this.editData[row] = $event.target.value;
        let total =
          Number(this.editData.usualPerformance) +
          Number(this.editData.finalPerformance) +
          Number(this.editData.otherPerformance);
        if (total > 100) {
          this.Warn("成绩权重分配总比例不符合100%,请查验后重试");
        }
        if ($event.target.value == "") {
          this.$nextTick(() => {
            this.editData[row] = 0;
          });
        }
      } else if (type == "maxScore") {
        // 判断平时成绩是否超过100
        this.editData[row].maxScore = $event.target.value;
        let total =
          Number(this.editData.loginItem.maxScore) +
          Number(this.editData.courseStudy.maxScore) +
          Number(this.editData.liveStudy.maxScore) +
          Number(this.editData.ebookStudy.maxScore) +
          Number(this.editData.interactive.maxScore) +
          Number(this.editData.stations.maxScore) +
          Number(this.editData.work.maxScore);
        if (total > 100) {
          this.Warn("平时成绩总分不符合100分，请查验后重试");
        }
        // 判断每次分数是否超过最高分，如果超过就改变每次分数
        if (
          Number(this.editData[row].maxScore) < Number(this.editData[row].value)
        ) {
          this.editData[row].value = Number(this.editData[row].maxScore);
        }
        if ($event.target.value == "") {
          // this.editData[row].value = Number(0);
          this.$nextTick(() => {
            this.$set(this.editData[row], "maxScore", 0);
          });
        }
      } else if (type == "value") {
        this.editData[row].value = Number($event.target.value);
        if (Number(this.editData[row].maxScore) < Number($event.target.value)) {
          this.$nextTick(() => {
            this.$set(
              this.editData[row],
              "value",
              Number(this.editData[row].maxScore)
            );
          });
          return;
        } else {
          this.editData[row].value = Number($event.target.value);
        }
      } else if (type == "minute") {
        this.editData[row].minute = Number($event.target.value);
        if (!$event.target.value) {
          this.$nextTick(() => {
            this.$set(this.editData[row], "minute", 0);
          });
          return;
        } else {
          this.editData[row].minute = Number($event.target.value);
        }
      }
    },
    regDateEvent(row) {
      let val = "";
      let regVal = row.cooperationStatus;
      switch (regVal) {
        case 1:
          val = "试用中";
          break;
        case 2:
          val = "合约中";
          break;
        case 3:
          val = "过期中";
          break;
      }
      return val;
    },
    jumpStatus(row) {
      let { status } = row;
      if(status == 5){
        return;
      }
      this.$router.push({
        name: "addSchool",
        params: {
          status,
          headTitle: "学校信息",
          id: row.id
        }
      });
    },
    // 编辑
    editSchool(row) {
      let { status } = row;
      this.$router.push({
        name: "addSchool",
        params: {
          status: 5,
          headTitle: "学校信息",
          id: row.id
        }
      });
    },
    stuCount(row) {
      this.$router.push({
        name: "studentSituation",
        query: {
          id: row.id,
          headTitle: `${row.name}学生情况`
        }
      });
    },
    handleClick(row) {
      let storageLogin = JSON.parse(sessionStorage.getItem("loginData"));
      // let obj = {
      //   loginData: {
      //     access_token: storageLogin.access_token,
      //     refresh_token: storageLogin.refresh_token,
      //     user_info: {
      //       firstLogin: storageLogin.user_info.firstLogin,
      //       id: storageLogin.user_info.id,
      //       password: storageLogin.user_info.password,
      //       phone: storageLogin.user_info.phone,
      //       tenantId: storageLogin.user_info.tenantId,
      //       type: storageLogin.user_info.type,
      //       username: storageLogin.user_info.username,
      //     },
      //   },
      //   tenant_id: row.id,
      // };
      // window.open("http://localhost:8080/#/index?loginData=" + JSON.stringify(obj));
      // window.open(row.webSite + "/#/index?loginData=" + JSON.stringify(obj));

      let obj = {
        token: storageLogin.access_token,
        refresh_token: storageLogin.refresh_token,
        tenantId: row.id,
        platformId: storageLogin.user_info.tenantId,
        appVersion: navigator.appVersion
      };
      // window.open(`http://localhost:80/#/index?token=${obj.token}&refresh_token=${obj.refresh_token}&tenantId=${obj.tenantId}&platformId=${obj.platformId}&appVersion=${obj.appVersion}`);
      window.open(
        `${row.webSite}/#/index?token=${obj.token}&refresh_token=${obj.refresh_token}&tenantId=${obj.tenantId}&platformId=${obj.platformId}&appVersion=${obj.appVersion}`
      );
    },
    addSEvent() {
      this.$router.push({
        name: "addSchool",
        query: {
          // status,
          headTitle: "添加学校"
        }
      });
    },
    onSubmit() {
      // if(this.timeVal) {
      //   this.formInline.startTime = way.dateReturnTimestamp(this.timeVal[0]);
      //   this.formInline.endTime = way.dateReturnTimestamp(this.timeVal[1]);
      // }
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTableData();
    },
    // 重置
    resetEvent() {
      let nature = this.formInline.nature
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.formInline.nature = nature
    },
    // 发布
    release(row) {
      this.$confirm("请确认是否发布？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$request.schoolPublish({ id: row.id }).then(res => {
            if (res.data.code == 0) {
              this.getTableData();
              this.Success(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    //下架
    offSchool(row) {
      this.$confirm("请确认是否下架学校？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
       .then(() => {
          this.$request.offSchool({ id: row.id }).then(res => {
            if (res.data.code == 0) {
              this.getTableData();
              this.Success(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },

    //重新上架
    onSchool(row) {
      this.$confirm("请确认是否重新上架学校？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
       .then(() => {
          this.$request.onSchool({ id: row.id }).then(res => {
            if (res.data.code == 0) {
              this.getTableData();
              this.Success(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    changePage(val) {
      // console.log(val)
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getTableData();
    },
    // 跳转学生管理
    jumpStudent(row) {
      this.$router.push({
        name: "studentManagement",
        query: {
          tenantId: row.id,
          encode: row.encoding
        }
      });
    },
    // 跳转教学计划
    jumpTeach(row) {
      // sessionStorage.setItem('key', '2-2');
      // this.$store.commit('watchKeyEvent', '2-2');
      this.$router.push({
        name: "teachPlan",
        query: {
          tenantId: row.id
        }
      });
    },
    // 跳转课程管理
    jumpCourse(row) {
      this.$router.push({
        name: "courseManage",
        query: {
          tenantId: row.id,
          encode: row.encoding
        }
      });
    },

    // 得分规则
    dialogDiyEvent(row, type) {
      this.isShowRule = !this.isShowRule;
      if (type == "open") {
        this.getScoreRule(row.id);
      }
    },
    getScoreRule(id) {
      let obj = { tenantId: id };
      this.tenantId = id;
      this.$request.getScoreRule(obj, res => {
        if (res.code == 0) {
          this.editData = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    enterEvent() {
      let total =
        Number(this.editData.usualPerformance) +
        Number(this.editData.finalPerformance) +
        Number(this.editData.otherPerformance);
      if (total > 100) {
        this.Warn("成绩权重分配总比例不符合100%,请查验后重试");
        return;
      }
      let total2 =
        Number(this.editData.loginItem.maxScore) +
        Number(this.editData.courseStudy.maxScore) +
        Number(this.editData.liveStudy.maxScore) +
        Number(this.editData.ebookStudy.maxScore) +
        Number(this.editData.interactive.maxScore) +
        Number(this.editData.stations.maxScore) +
        Number(this.editData.work.maxScore);
      if (total2 > 100) {
        this.Warn("平时成绩总分不符合100分，请查验后重试");
        return;
      }
      let id = this.tenantId;
      let obj = JSON.parse(JSON.stringify(this.editData));

      this.$request.scoreRulePublish(id, obj).then(res => {
        if (res.data.code == 0) {
          this.Success("修改成功");
          this.dialogDiyEvent();
        }
      });
    },
    jumpWebSite(row) {
      window.open(row.webSite);
    },
    // 资源匹配模板
    jumpResources(row) {
      let query = {
        tenantId: row.id
      }
      this.$router.push({name: 'resourceModule', query})
    },
    // 学校设置
    setEvent(row) {
      let query = {
        tenantId: row.id
      }
      this.$router.push({name: 'schoolSet', query})
    }
  }
};
</script>

<style lang="less">
.schoolList {
  height: 100%;
  .elScroll {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .search_sList {
    background-color: #fff;
    padding: 28px 40px;
    .top_opera {
      display: flex;
      .el-form {
        flex: 4;
        .el-form-item__content {
          width: 218px;
        }
      }
      .btn_area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .st_one {
    cursor: pointer;
    color: #ff8a0c;
  }
  .st_two {
    cursor: pointer;
    color: #1282ff;
  }
  .st_three {
    cursor: pointer;
    color: #606266;
  }
  .table_sList {
    box-sizing: border-box;
    padding: 30px 40px;
    margin-top: 10px;
    background-color: #fff;
    .opera_btnArea {
      margin-bottom: 20px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .el-pagination {
      text-align: right;
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.pubToop {
  max-width: 600px;
}
.ruleScoring_area {
  height: 100%;
  box-sizing: border-box;
  // padding: 30px;
  color: #363840;
  .block_module {
    border-bottom: 1px solid #efefef;
    .m-title {
      color: #363840;
      font-size: 15px;
    }
    .m-cont {
      font-size: 14px;
      padding: 20px 0;
    }
    .achievement {
      display: flex;
      justify-content: space-between;
    }
    .a_pub {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .achieve {
        width: 64px;
      }
      div:nth-of-type(3) {
        margin-left: 6px;
      }
    }
    .a_pub_new {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 6px;
      .achieve {
        display: inline-block;
      }
      div:nth-of-type(3) {
        margin-left: 6px;
      }
    }
    .fans {
      margin-top: 20px;
    }
    .el-form-item {
      display: block;
      display: flex;
      .el-form-item__content {
        display: block;
        display: flex;
      }
    }
    .el-input {
      width: 50px;
      .el-input__inner {
        width: 50px;
        padding: 0 8px;
      }
    }
  }
  .final {
    display: flex;
    font-size: 12px;
    color: #363840;
    border-bottom: 1px solid transparent;
    padding: 20px 0;
  }
  .rule_btn_area {
    text-align: right;
    .el-button {
      width: 70px;
      height: 34px;
      font-size: 15px;
      padding: 0;
    }
  }
}
</style>

<style lang="less" scoped>
  .point {
    cursor: pointer;
    color: #409EFF;
  }
  .dialogScroll {
    height: calc(100% - 70px);
    width: 100%;
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    /deep/ .el-scrollbar__view {
      overflow-x: hidden;
      padding: 30px;
    }
  }
</style>
